import isEmpty from 'lodash-es/isEmpty';
import { actions } from '@groove-labs/groove-ui';
import Raven from 'raven-js';

import {
  call,
  delay,
  put,
  select,
  take,
} from 'redux-saga/effects';
import {
  actionTypes, associateNoteToEvent, logEventToSalesforce,
} from 'Modules/Note/actions';
import { getQueryParameters } from 'Modules/App/selectors';
import { pushQueryParameters } from 'Utils/history';
import VisualforceClient from 'Utils/VisualforceClient';
import Note from 'Modules/Note/data/Note';
import {
  getNote,
  getLastSavedAt,
} from 'Modules/Note/selectors';
import { VisualforceError } from 'Modules/Note/errors';
import { CHOOSE_MEETING_BUTTON_ENABLED_UI_KEY_PATH } from 'Modules/Note/constants';
import replaceHtmlCharacterCodes from 'Utils/replaceHtmlCharacterCodes';
import replaceSmartQuotes from 'Utils/replaceSmartQuotes';
import transformNewlinesToParagraphs from 'Utils/transformers/transformNewlinesToParagraphs';

const { setProperty } = actions.ui;

function* createNote({
  eventId,
  body,
}) {
  const response = yield call([VisualforceClient, 'call'], 'GrooveMeetingNotes', {
    mode: 'post',
    note_content: btoa(body),
  });

  if (isEmpty(response)) {
    throw new VisualforceError([{ title: 'Unexpected HTML response' }]);
  }

  const noteId = response.data.id;
  const params = {
    noteId,
    eventId,
  };
  yield call(pushQueryParameters, params);

  return noteId;
}

function* updateNote({
  noteId,
  body,
}) {
  const response = yield call([VisualforceClient, 'call'], 'GrooveMeetingNotes', {
    mode: 'patch',
    note_id: noteId,
    note_content: btoa(body),
  });
  if (isEmpty(response)) {
    throw new VisualforceError([{ title: 'Unexpected HTML response' }]);
  }
}

/**
 * @description Handles multiple cases
 * 1. Updating text for existing notes that are associated with a meeting
 * 2. When creating a note draft
 * 3. Associating a note draft with a meeting
 * @param {string?} selectedEventId Parameter passed in when the function
 * operates on a note with no event associated with it
 */
export default function* handleSaveNote({ payload: {
  body, selectedEventId,
} }) {
  const transformers = [
    replaceSmartQuotes,
    replaceHtmlCharacterCodes,
    transformNewlinesToParagraphs,
  ];

  const transformedBody = transformers.reduce((body, transformer) => transformer(body), body);

  const queryParameters = yield select(getQueryParameters);
  yield delay(1000);
  yield put({ type: actionTypes.SAVE_NOTE.PROGRESS });
  let noteId = queryParameters.get('noteId');
  const eventId = queryParameters.get('eventId') || selectedEventId;
  try {
    if (eventId) {
      const lastSavedAt = yield select(getLastSavedAt);

      // if we've saved the note at least once, assume the
      // event has been logged to Salesforce
      if (!lastSavedAt) {
        const existingEvent = yield call([VisualforceClient, 'call'], 'DaScoopComposer__GrooveEvents', {
          uid: eventId,
        });
        if (existingEvent.resultMessage !== 'true') {
          yield put(logEventToSalesforce({ eventId }));
          yield take(actionTypes.LOG_EVENT_TO_SALESFORCE.SUCCESS);
        }
      }
    }

    if (noteId === 'new') {
      noteId = yield call(createNote, {
        noteId,
        eventId,
        body: transformedBody,
      });
    } else {
      yield call(updateNote, {
        noteId,
        body: transformedBody,
      });
    }

    const existingNote = (yield select(getNote, { noteId })) || new Note();

    yield put({
      type: actionTypes.SAVE_NOTE.SUCCESS,
      payload: existingNote.merge({
        id: noteId,
        body,
      }),
    });
    if (eventId) {
      yield put(associateNoteToEvent({
        noteId,
        eventId,
      }));
    }
  } catch (e) {
    Raven.captureException(e);
    yield put({
      type: actionTypes.SAVE_NOTE.FAILURE,
      payload: e.name === VisualforceError.name ? e.errors : e,
    });
  }
  yield delay(250);
  yield put(setProperty({
    uiKeyPath: CHOOSE_MEETING_BUTTON_ENABLED_UI_KEY_PATH,
    data: true,
  }));
}
